import React, {Component, createRef} from 'react'
import jQuery from 'jquery'

export default class extends Component {
  constructor (props) {
    super(props)

    this.ref = createRef()
    this.scrollHandler = this.scrollHandler.bind(this)
    this.state = {
      scrollTop: 0
    }
  }

  scrollHandler () {
    if (typeof window === 'undefined') {
      return null
    }

    const {factor = 1} = this.props
    const $window = jQuery(window)
    const $ref = jQuery(this.ref.current)
    const scrollTop = $window.scrollTop()
    const refHeight = $ref.height()
    const active = scrollTop >= $ref.offset().top
      && $ref.offset().top + refHeight >= scrollTop

    if (active) {
      this.setState({
        scrollTop: scrollTop * +factor
      })
    }
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      jQuery(window).on('scroll', this.scrollHandler)
    }
  }

  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      jQuery(window).off('scroll', this.scrollHandler)
    }
  }

  render () {
    const {scrollTop} = this.state
    const style = {transform: `translateY(${scrollTop}px)`}

    return (
      <div ref={this.ref}>
        <div style={style}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
