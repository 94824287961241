import React, {Component, createRef} from "react"
import Helment from 'react-helmet'
import { graphql, Link } from "gatsby"
import jQuery from 'jquery'
import { withTranslation } from '../services/i18next'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import Article from '../components/article'
import Parallax from '../components/parallax'
import ContactUs from '../components/contact-us'
import SVG from '../components/svg'

import IntroContext from '../contexts/index/intro'
import cleanUpQuotes from '../utils/cleanup-quotes'

import ARLogo from '../images/ar-02.svg'
import ENLogo from '../images/en-02.svg'

import Arrow1 from '../images/arrow-1.svg'
import Arrow2 from '../images/arrow-2.svg'
import Logo from '../images/logo-rest.svg'
import LogoHover from '../images/logo-hover.svg'

class Index extends Component {
  constructor (props) {
    super(props)

    this.scrollHandler = this.scrollHandler.bind(this)
    this.introButtonClickHandler = this.introButtonClickHandler.bind(this)
    this.introButtonMouseEnterHandler = this.introButtonMouseEnterHandler.bind(this)
    this.introButtonMouseLeaveHandler = this.introButtonMouseLeaveHandler.bind(this)
    this.scrollTop = this.scrollTop.bind(this)
    this.ref = createRef()

    this.changeActiveArea = (n) => {
      this.setState({
        activeArea: n
      })
    }

    this.state = {
      introButtonClickHandler: this.introButtonClickHandler,
      changeActiveArea: this.changeActiveArea,
      activeArea: null,
      stickyHeaderActive: false,

      aboutProject: null,
      maps: null,
      articles: null,
      multimedia: null,
      isReady: false,
    }
  }

  introButtonClickHandler (e) {
    e.preventDefault()

    const target = e.currentTarget
    const $target = jQuery(target)
    const sectionName = $target.attr('href') || $target.data('href')
    const $ref = jQuery(this.ref.current)
    const $section = $ref.find(sectionName)
    const $header = $ref.find('header.main-header')

    if ($section.length) {
      jQuery('html, body').animate({
        scrollTop: $section.offset().top - $header.height() + 5
      }, 4e2)
    }
  }

  introButtonMouseEnterHandler (e) {
    const target = e.target
    const $target = jQuery(target)
    const index = $target.parent().index()
    const {activeArea} = this.state

    if (activeArea !== index) {
      this.setState({
        activeArea: index
      })
    }
  }

  introButtonMouseLeaveHandler (e) {
    const {activeArea} = this.state

    if (activeArea !== null) {
      this.setState({
        activeArea: null
      })
    }
  }

  scrollHandler () {
    if (typeof window === 'undefined') {
      return
    }

    const $window = jQuery(window)
    const $ref = jQuery(this.ref.current)
    const $header = $ref.find('header.main-header')
    const scrollTop = $window.scrollTop()
    const offset = $window.height() - $header.height()
    const {stickyHeaderActive} = this.state

    if (scrollTop >= offset) {
      if (!stickyHeaderActive) {
        this.setState({
          stickyHeaderActive: true
        })
      }
    } else {
      if (stickyHeaderActive) {
        this.setState({
          stickyHeaderActive: false
        })
      }
    }
  }

  scrollTop () {
    jQuery('html, body').animate({
      scrollTop: 0
    }, 4e2)
  }

  componentDidMount () {
    const {data} = this.props
    const {
      aboutProject,
      maps,
      articles: {nodes: articles},
      multimedia: {nodes: multimedia}
    } = data

    articles.map(
      article => {
        return article
      }
    )

    this.setState({
      aboutProject,
      maps,
      articles,
      multimedia,
      isReady: true,
    })

    if (typeof window !== 'undefined') {
      jQuery(window).on('scroll', this.scrollHandler)
    }
  }

  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      jQuery(window).off('scroll', this.scrollHandler)
    }
  }

  render() {
    const {
      stickyHeaderActive,
      activeArea,
      aboutProject,
      maps,
      articles,
      multimedia,
      isReady,
    } = this.state

    const {data, t, i18n} = this.props
    const {site: {siteMetadata}} = data
    const {description} = siteMetadata

    if (!isReady) {
      return (
        <SEO />
      )
    }

    return (
      <Layout>
        <SEO />

        <section ref={this.ref}>
          <section className={`header-container ${stickyHeaderActive ? 'active' : ''}`}>
            <Header />
          </section>

          <section id="intro" className="intro">
            <Parallax factor="0.8">
              <div className="inner d-flex justify-content-center align-items-center">
                <IntroContext.Provider value={this.state}>
                  <SVG className="d-none d-lg-block" />
                </IntroContext.Provider>

                <nav className="d-flex flex-column align-items-center h-100 overflow-hidden pt-lg-5">
                  <div className="logo mb-lg-4">
                    <Link
                      id="logo"
                      className="d-flex justify-content-center align-items-center"
                      to={this.props.i18n.language === 'en' ? '/en' : '/'}
                    >
                      <img className="image rest" width="200" src={Logo} alt="" />
                      <img className="image hover" width="200" src={LogoHover} alt="" />
                    </Link>
                  </div>

                  <ul className="nav d-flex flex-column text-center">
                    <li className={`nav-item ${activeArea === 0 ? 'active' : ''}`}>
                      <a
                        onMouseEnter={this.introButtonMouseEnterHandler}
                        onMouseLeave={this.introButtonMouseLeaveHandler}
                        onClick={this.introButtonClickHandler}
                        className="nav-link" href="#about-us">
                        {t('about')}
                      </a>
                    </li>
                    <li className={`nav-item ${activeArea === 1 ? 'active' : ''}`}>
                      <a
                        onMouseEnter={this.introButtonMouseEnterHandler}
                        onMouseLeave={this.introButtonMouseLeaveHandler}
                        onClick={this.introButtonClickHandler}
                        className="nav-link" href="#maps">
                        {t('maps')}
                      </a>
                    </li>
                    <li className={`nav-item ${activeArea === 2 ? 'active' : ''}`}>
                      <a
                        onMouseEnter={this.introButtonMouseEnterHandler}
                        onMouseLeave={this.introButtonMouseLeaveHandler}
                        onClick={this.introButtonClickHandler}
                        className="nav-link" href="#articles">
                        {t('articles')}
                      </a>
                    </li>
                    <li className={`nav-item ${activeArea === 3 ? 'active' : ''}`}>
                      <a
                        onMouseEnter={this.introButtonMouseEnterHandler}
                        onMouseLeave={this.introButtonMouseLeaveHandler}
                        onClick={this.introButtonClickHandler}
                        className="nav-link" href="#multimedia">
                        {t('multimedia')}
                      </a>
                    </li>
                    <li className={`nav-item ${activeArea === 4 ? 'active' : ''}`}>
                      <a
                        onMouseEnter={this.introButtonMouseEnterHandler}
                        onMouseLeave={this.introButtonMouseLeaveHandler}
                        onClick={this.introButtonClickHandler}
                        className="nav-link" href="#contact-us">
                        {t('contact')}
                      </a>
                    </li>
                    <li className={`nav-item ${activeArea === 5 ? 'active' : ''}`}>
                      <Link
                        onMouseEnter={this.introButtonMouseEnterHandler}
                        onMouseLeave={this.introButtonMouseLeaveHandler}
                        className="nav-link"
                        to={this.props.i18n.language === 'ar' ? '/en' : '/'}
                      >
                        {
                          this.props.i18n.language === 'en' ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <span className="fa icon-ar-02 fa-2x font-weight-normal"></span>
                            </div>
                          )
                          : (
                            <span className="fa icon-en-02 fa-xs font-weight-normal"></span>
                          )
                        }
                      </Link>
                    </li>
                  </ul>

                  <div className="arrows mt-auto px-4 d-none d-lg-block">
                    <img className="d-block w-100" src={Arrow1} alt="" />
                    <img className="d-block" src={Arrow2} alt="" />
                  </div>
                </nav>
              </div>
            </Parallax>
          </section>
          <section id="about-us" className="with-bg minimum-full-height d-flex flex-column justify-content-center align-items-center position-relative">
            <div className="container">
              <article className="py-4">
                <div className="decorator d-none d-lg-block my-5"></div>
                <header>
                  <h1>{aboutProject.title}</h1>
                </header>
                <section dangerouslySetInnerHTML={{__html: cleanUpQuotes(aboutProject.excerpt)}}></section>
                <div className="decorator d-none d-lg-block my-5 mr-auto"></div>
                <div className="d-flex justify-content-center mb-4">
                  <Link to={`${i18n.language === 'en' ? '/en' : ''}/about-us`} className="btn btn-theme py-2 px-5">
                    {t('about')}
                  </Link>
                </div>
              </article>
            </div>

            <section className="move-up-container">
              <div className={`move-up ${stickyHeaderActive ? 'active' : ''}`}>
                <button onClick={this.scrollTop} className="btn d-flex justify-content-center align-items-center">
                  <i className="caret-up"></i>
                </button>
              </div>
            </section>
          </section>
          <section
            id="maps"
            className="vh-100 bg-black d-flex flex-column justify-content-center align-items-center position-relative"
          >
            <div className="bg" />
            <div className="container">
              <article className="py-4">
                <section className="row">
                  <div className="col-lg-8">
                    <div className="decorator d-none d-lg-block my-5" />
                    <div dangerouslySetInnerHTML={{__html: cleanUpQuotes(maps.excerpt)}} />
                    <div className="decorator d-none d-lg-block my-5 mr-auto" />
                    <div className="d-flex justify-content-center justify-content-lg-end mb-4">
                      <Link to={`${i18n.language === 'en' ? '/en' : ''}/maps`} className="btn btn-theme py-2 px-5">
                        {t('maps')}
                      </Link>
                    </div>
                  </div>
                </section>
              </article>
            </div>

            <section className="move-up-container">
              <div className={`move-up light ${stickyHeaderActive ? 'active' : ''}`}>
                <button onClick={this.scrollTop} className="btn d-flex justify-content-center align-items-center">
                  <i className="caret-up"></i>
                </button>
              </div>
            </section>
          </section>
          <section id="articles" className="articles position-relative">
            <div className="py-5">
              <div className="container">
                {
                  articles.map(
                    (article, i) => (
                      <Article
                        key={i.toString()}
                        className="mb-5"
                        data={article}
                      />
                    )
                  )
                }

                <div className="d-flex justify-content-center mt-5">
                  <Link to={`${i18n.language === 'en' ? '/en' : ''}/articles`} className="btn btn-theme py-2 px-5">
                    {t('articles')}
                  </Link>
                </div>
              </div>
            </div>
            <section className="move-up-container">
              <div className={`move-up ${stickyHeaderActive ? 'active' : ''}`}>
                <button onClick={this.scrollTop} className="btn d-flex justify-content-center align-items-center">
                  <i className="caret-up"></i>
                </button>
              </div>
            </section>
          </section>
          <section id="multimedia" className="articles bg-black position-relative">
            <div className="py-5">
              <div className="container">
                {
                  multimedia.map(
                    (article, i) => (
                      <Article
                        key={i.toString()}
                        className="mb-5"
                        data={article}
                      />
                    )
                  )
                }

                <div className="d-flex justify-content-center mt-5">
                  <Link to={`${i18n.language === 'en' ? '/en' : ''}/multimedia`} className="btn btn-theme py-2 px-5">
                    {t('multimedia')}
                  </Link>
                </div>
              </div>
            </div>
            <section className="move-up-container">
              <div className={`move-up light ${stickyHeaderActive ? 'active' : ''}`}>
                <button onClick={this.scrollTop} className="btn d-flex justify-content-center align-items-center">
                  <i className="caret-up"></i>
                </button>
              </div>
            </section>
          </section>
          <section id="contact-us" className="position-relative">
            <section className="move-up-container">
              <div className={`move-up ${stickyHeaderActive ? 'active' : ''}`}>
                <button onClick={this.scrollTop} className="btn d-flex justify-content-center align-items-center">
                  <i className="caret-up"></i>
                </button>
              </div>
            </section>

            <ContactUs />

            <Footer />
          </section>
        </section>
      </Layout>
    )
  }
}

export default withTranslation(Index)
